import React, { useEffect, useRef } from 'react';
import { Fade } from 'react-awesome-reveal';
import { Col, Container, Row } from 'react-bootstrap';
import { BsEnvelope, BsPhone, BsWhatsapp } from 'react-icons/bs';
import { FiPhoneCall } from 'react-icons/fi';
import { MdOutlineLocationOn } from 'react-icons/md';
import { useLocation, useParams } from 'react-router-dom';
import { checkURL, fixGoogleMaps } from './../../helpers/general';

// Redux
import { useSelector } from 'react-redux';

// i18next
import { useTranslation } from 'react-i18next';

// Styles
import './ContactPage.styles.css';

// Components
import ContactFormComponent from './../../components/ContactFormComponent/ContactFormComponent';
import HeroSectionComponent from './../../components/HeroSectionComponent/HeroSectionComponent';
import IconTextComponent from './../../components/IconTextComponent/IconTextComponent';
import MetaTagsComponent from './../../components/MetaTagsComponent/MetaTagsComponent';

const ContactPage = () => {
	const location = useLocation();

	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'en');
		// eslint-disable-next-line
	}, [lang]);

	const ICON = { SIZE: 24 };

	const contactFormRef = useRef();

	// Redux
	const {
		settings: { address, map },
		contacts: { mobile, telephone, email, social, whatsapp },
		pages: { contactHero, wantToVisit, getInTouch },
	} = useSelector((state) => state.settingsData);

	// Scroll To Top On Initial Render
	useEffect(() => {
		if (location.state?.goToContactForm) {
			location.state = null;
			contactFormRef.current.scrollIntoView({
				block: 'start',
				inline: 'start',
				behavior: 'smooth',
			});
		} else {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			});
		}
	}, [lang, location]);

	return (
		<Container
			fluid
			lang='en'
			dir='ltr'
			id='contact-us-page'
			className='page px-0'
			style={{
				minHeight: '100vh',
			}}
		>
			{/* MetaTags */}
			<MetaTagsComponent pageTitle={t('words:windowTab.contactUs')} />

			{/* Want To Chat */}
			<HeroSectionComponent content={contactHero} />

			{/* Get In Touch */}
			<Container
				fluid
				className='contact-container position-relative'
				ref={contactFormRef}
			>
				<Container className='custom-container'>
					{/* Section Header */}
					<Row xs={1} className='header g-4'>
						{/* Title */}
						<Fade triggerOnce={true} direction='left' delay={20}>
							<Col
								dangerouslySetInnerHTML={{ __html: getInTouch.title }}
								className='title serif-font'
							></Col>
						</Fade>

						{/* Description */}
						<Fade triggerOnce={true} direction='left' delay={40}>
							<Col
								dangerouslySetInnerHTML={{
									__html: getInTouch.description,
								}}
								className='description'
							></Col>
						</Fade>
					</Row>

					{/* Content */}
					<Row className='g-4'>
						{/* Contact Form */}
						<Col xs={12} lg={8}>
							<ContactFormComponent postRoute='/quotation' />
						</Col>

						{/* Contact Details Container */}
						<Col xs={12} lg={4} className='text-container'>
							{/* Contact Info */}
							<Row className='g-4'>
								{/* Address */}
								<Col xs={12} className='d-flex flex-column'>
									<h3 className='title'>{t('words:address')}</h3>

									<IconTextComponent
										icon={<MdOutlineLocationOn />}
										text={address}
										isCentered={false}
										styles={{
											container: {
												'--size': ICON.SIZE,
												marginBottom: '0.5rem',
											},
										}}
									/>
								</Col>

								<Col xs={12} className='d-flex flex-column'>
									<h3 className='title'>{t('words:contact')}</h3>

									{/* Telephones */}
									{telephone.length > 0 &&
										telephone.map((telephone, index) => (
											<IconTextComponent
												key={index}
												icon={<FiPhoneCall />}
												text={
													<Container
														fluid
														className='value p-0'
														style={{
															direction: 'ltr',
														}}
													>
														<a href={`tel:${telephone.contact}`}>
															{telephone.contact}
														</a>
													</Container>
												}
												isCentered={false}
												styles={{
													container: {
														'--size': ICON.SIZE,
														marginBottom: '0.5rem',
													},
												}}
											/>
										))}

									{/* Phones */}
									{mobile.length > 0 &&
										mobile.map((mobile, index) => (
											<IconTextComponent
												key={index}
												icon={<BsPhone />}
												text={
													<Container
														fluid
														className='value p-0'
														style={{
															direction: 'ltr',
														}}
													>
														<a href={`tel:${mobile.contact}`}>
															{mobile.contact}
														</a>
													</Container>
												}
												isCentered={false}
												styles={{
													container: {
														'--size': ICON.SIZE,
														marginBottom: '0.5rem',
													},
												}}
											/>
										))}

									{/* Whatsapp Number */}
									{whatsapp.length &&
										whatsapp.map((whatsapp, index) => (
											<IconTextComponent
												key={index}
												icon={<BsWhatsapp />}
												text={
													<Container
														fluid
														className='value p-0'
														style={{
															direction: 'ltr',
														}}
													>
														<a
															href={`https://wa.me/${whatsapp.contact}`}
															target='_blank'
															rel='noreferrer'
														>
															{whatsapp.contact}
														</a>
													</Container>
												}
												isCentered={false}
												styles={{
													container: {
														'--size': ICON.SIZE,
														marginBottom: '0.5rem',
													},
												}}
											/>
										))}

									{/* Emails */}
									{email.length > 0 &&
										email.map((email, index) => (
											<IconTextComponent
												key={index}
												icon={<BsEnvelope />}
												text={
													<Container fluid key={index} className='value p-0'>
														<a href={`mailto:${email.contact}`}>
															{email.contact}
														</a>
													</Container>
												}
												isCentered={false}
												isCapitalized={false}
												styles={{
													container: {
														'--size': ICON.SIZE,
													},
												}}
											/>
										))}
								</Col>

								<Col sx={12} className='social-accounts d-flex flex-column'>
									<h3 className='title'>{t('words:socials')}</h3>

									{/* Socials */}
									{social.length > 0 && (
										<div className='d-flex align-items-center'>
											{social.map((social, index) => (
												<a
													key={index}
													href={checkURL(social.contact)}
													target='_blank'
													rel='noreferrer'
													className={`icon ${social.icon}`}
													style={{
														'--icon-color': social.contact.includes('facebook')
															? '#1877f2'
															: social.contact.includes('twitter')
															? '#1da1f2'
															: social.contact.includes('linkedin')
															? '#0077b5'
															: social.contact.includes('instagram')
															? '#f56040'
															: social.contact.includes('youtube')
															? '#ff0000'
															: '#34bf49',
													}}
												>
													{''}
												</a>
											))}
										</div>
									)}
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</Container>

			{/* Want To Visit */}
			<Container fluid className='map-section px-0'>
				<Row
					xs={1}
					md={2}
					className='px-0 mx-0 overflow-hidden'
					style={{ minHeight: '50vh' }}
				>
					{/* Want To Visit */}
					<Col className='text-container px-0'>
						<Container className='h-100'>
							<Row
								xs={1}
								className='h-100 custom-container d-flex flex-column justify-content-center align-items-center'
							>
								{/* Title */}
								<Col
									className='title serif-font'
									dangerouslySetInnerHTML={{ __html: wantToVisit.title }}
								></Col>

								{/* Description */}
								<Col
									className='description'
									dangerouslySetInnerHTML={{
										__html: wantToVisit.description,
									}}
								></Col>
							</Row>
						</Container>
					</Col>

					{/* Map */}
					<Col
						className='map-container px-0 mx-0'
						dangerouslySetInnerHTML={{
							__html: fixGoogleMaps(map),
						}}
					></Col>
				</Row>
			</Container>
		</Container>
	);
};

export default ContactPage;
