export const BASE_URL = {
	local: 'http://localhost:8000/api',
	// demo: 'https://mse-dashboard.hansmbill.net/api',
	demo: 'https://mse-dashboard.professional-it.tech/api',
};

export const checkURL = (urlLink) => {
	return urlLink.includes('http://') || urlLink.includes('https://')
		? urlLink
		: `https://${urlLink}`;
};

export const fixGoogleMaps = (urlLink) => {
	return urlLink.replace('www.google.com', 'maps-api-ssl.google.com');
};

export const trimEmptyTags = (htmlText, tag = 'p') => {
	const re = new RegExp(`<${tag}>&nbsp;</${tag}>`, 'gi');

	return htmlText.replace(re, '');
};

export const filterByCategory = (
	selectedCategory,
	productCategory,
	productIndex,
	currentProductsCount,
	clearFilterKey
) => {
	if (selectedCategory === clearFilterKey) {
		return true;
	}

	return (
		productCategory.toLowerCase().trim() ===
		selectedCategory.toLowerCase().trim()
	);
};
