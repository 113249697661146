import React, { useEffect } from 'react';
import { Card, Container, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { MdDelete, MdOutlineAdd } from 'react-icons/md';
import { useParams } from 'react-router-dom';

// i18next
import { useTranslation } from 'react-i18next';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, deleteFromCart } from './../../store/slices/cart.reducer';

// Styles
import './ProductCardComponent.styles.css';

// Components
import ButtonComponent from './../ButtonComponent/ButtonComponent';

const ProductCardComponent = ({ product }) => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'en');
		// eslint-disable-next-line
	}, [lang]);

	// Redux
	const dispatch = useDispatch();
	const cart = useSelector((state) => state.cart);

	return (
		<Container
			lang='en'
			dir='ltr'
			fluid
			className='product-card-component px-0 position-relative'
			style={{
				'--shortcut-color': product.category_shortcut
					.toLowerCase()
					.trim()
					.includes('tm')
					? '#F77053'
					: product.category_shortcut.toLowerCase().trim().includes('tc')
					? '#00D6AA'
					: product.category_shortcut.toLowerCase().trim().includes('jt')
					? '#C44C8C'
					: product.category_shortcut.toLowerCase().trim().includes('tum')
					? '#FFBE3A'
					: product.category_shortcut.toLowerCase().trim().includes('a')
					? '#1D2E40'
					: product.category_shortcut.toLowerCase().trim().includes('set')
					? '#0CC2D6'
					: '#F15F69',
			}}
		>
			<Card className='product-card-container d-flex justify-content-center'>
				<Card.Body className='d-flex flex-column justify-content-center align-items-center'>
					{/* Image */}
					<Card.Img
						fluid='true'
						src={product.image}
						alt='product image'
						className='flex-grow-1 text-capitalize w-100 h-100'
						style={{
							objectFit: 'contain',
							objectPosition: 'center',
						}}
						onError={({ currentTarget }) => {
							currentTarget.onerror = null; // prevents looping
							currentTarget.src = require('./../../assets/images/logos/logo.png');
						}}
					/>

					{/* Code */}
					<Card.Text className='code'>{product.code}</Card.Text>
					{/* Category Shortcut */}
					<Card.Text className='category-shortcut text-uppercase d-flex justify-content-center align-items-center'>
						{product.category_shortcut.toLowerCase().trim()}
					</Card.Text>
				</Card.Body>
				<Card.Body className='d-flex flex-column'>
					{/* Title */}
					<Card.Title className='title text-limit' style={{ '--lines': 1 }}>
						{product.title}
					</Card.Title>

					{/* Description */}
					<Card.Text
						className='description text-limit'
						style={{ '--lines': 2 }}
						dangerouslySetInnerHTML={{ __html: product.description }}
					></Card.Text>

					{/* Dimensions */}
					<Card.Text className='dimensions d-flex flex-column'>
						<Card.Text as='span' className='details-title'>
							{t('words:dimensions')}
						</Card.Text>
						<Card.Text as='span'>{product.dimensions}</Card.Text>
					</Card.Text>

					{/* Type */}
					<Card.Text className='type d-flex flex-column'>
						<Card.Text as='span' className='details-title'>
							{t('words:type')}
						</Card.Text>
						<Card.Text
							as='span'
							className='text-limit'
							style={{ '--lines': 1 }}
						>
							{product.type}
						</Card.Text>
					</Card.Text>

					{/* Ask For Quotation */}
					<Card.Text
						as='span'
						style={{
							width: 'fit-content',
						}}
					>
						{cart.find((item) => product.id === item.id) ? (
							<OverlayTrigger
								key='remove-from-cart'
								placement='right'
								trigger={['hover', 'focus']}
								overlay={
									<Tooltip
										id='tooltip-remove-from-cart'
										className='text-capitalize'
									>
										{t('words:removeFromList')}
									</Tooltip>
								}
							>
								<span>
									<ButtonComponent
										icon={<MdDelete size={24} />}
										className='remove-from-cart'
										onClick={() => dispatch(deleteFromCart(product))}
									/>
								</span>
							</OverlayTrigger>
						) : (
							<OverlayTrigger
								key='add-to-cart'
								placement='right'
								trigger={['hover', 'focus']}
								overlay={
									<Tooltip id='tooltip-add-to-cart' className='text-capitalize'>
										{t('words:askForQuotation')}
									</Tooltip>
								}
							>
								<span>
									<ButtonComponent
										icon={<MdOutlineAdd size={24} />}
										className='add-to-cart'
										onClick={() => dispatch(addToCart(product))}
									/>
								</span>
							</OverlayTrigger>
						)}
					</Card.Text>
				</Card.Body>
			</Card>
		</Container>
	);
};

export default ProductCardComponent;
