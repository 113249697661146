import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

// Styles
import './SeparatorComponent.styles.css';

// Components

const SeparatorComponent = ({ content, id, hasSerifFont = false }) => {
	const { lang } = useParams();

	return (
		<Container
			lang='en'
			dir='ltr'
			fluid
			className='separator-component'
			id={id}
			style={{
				'--bg-image': `url(${content.image ?? ''})`,
			}}
		>
			{/* Main Text */}
			<Container className='text-container custom-container text-center overflow-hidden'>
				<Row
					xs={1}
					className='g-1 d-flex flex-column justify-content-center align-items-center'
				>
					{/* Subtitle */}
					{content.sub_title && (
						<Fade triggerOnce={true} direction='up' delay={100}>
							<Col className='subtitle'>{content.sub_title}</Col>
						</Fade>
					)}

					{/* Main Title */}
					{content.title && (
						<Fade triggerOnce={true} direction='up' delay={100}>
							<Col
								className={`title ${hasSerifFont ? 'serif-font' : ''}`}
								dangerouslySetInnerHTML={{ __html: content.title }}
							></Col>
						</Fade>
					)}

					{/* Main Description */}
					{content.description && (
						<Fade triggerOnce={true} direction='up' delay={100}>
							<Col
								dangerouslySetInnerHTML={{ __html: content.description }}
								className='description text-limit'
								style={{ '--lines': 10 }}
							></Col>
						</Fade>
					)}
				</Row>
			</Container>
		</Container>
	);
};

export default SeparatorComponent;
