import React, { useEffect } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from './../../routes/index.routes';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { clearCart } from './../../store/slices/cart.reducer';

// i18next
import { useTranslation } from 'react-i18next';

// Styles
import './CartModalComponent.styles.css';

// Components
import ButtonComponent from './../ButtonComponent/ButtonComponent';
import ProductCardComponent from './../ProductCardComponent/ProductCardComponent';

const CartModalComponent = (props) => {
	const navigate = useNavigate();

	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'en');
		// eslint-disable-next-line
	}, [lang]);

	// Redux
	const dispatch = useDispatch();
	const cart = useSelector((state) => state.cart);

	return (
		<Modal
			{...props}
			size='lg'
			aria-labelledby='cart-modal'
			centered
			className='cart-modal-component'
		>
			<Modal.Header closeButton>
				<Modal.Title
					id='cart-modal'
					className='pe-5 w-100 text-capitalize d-flex flex-wrap justify-content-between align-items-center'
				>
					{/* Modal Title */}
					<span>{t('words:askForQuotation')}</span>

					{/* Total Length */}
					<span>{t('sentences:cartLength', { count: cart.length })}</span>

					{/* Clear List Button */}
					<span>
						<ButtonComponent
							text={t('words:clearAll')}
							className='clear-cart'
							onClick={() => {
								dispatch(clearCart());
								props.onHide();
							}}
						/>
					</span>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Container fluid className='px-0 h-100'>
					{cart.length > 0 ? (
						<Row xs={1} md={2} className='g-4'>
							{cart.map((product, index) => (
								<Col key={index}>
									<ProductCardComponent product={product} />
								</Col>
							))}
						</Row>
					) : (
						<Row className='h-100 d-flex justify-content-center align-items-center'>
							<Col className='error'>
								{t('sentences:errors.emptyCart', {
									title: t('words:errors.products'),
								})}
							</Col>
						</Row>
					)}
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<ButtonComponent
					text={t('words:buttons.submitRequest')}
					onClick={() => {
						props.onHide();
						navigate(routes.contact, {
							state: { goToContactForm: true },
						});
					}}
				/>
			</Modal.Footer>
		</Modal>
	);
};

export default CartModalComponent;
