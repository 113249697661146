import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { Col, Container, Row } from 'react-bootstrap';

// Styles
import './HeroSectionComponent.styles.css';

const HeroSectionComponent = ({ content, className = '' }) => {
	return (
		<Container
			lang='en'
			dir='ltr'
			fluid
			className={`hero-section-component position-relative ${className}`}
			style={{
				'--bg-image': `url(${
					content.image ?? require('./../../assets/images/logos/logo.png')
				})`,
			}}
		>
			<Container className='text-container overflow-hidden'>
				<Row
					xs={1}
					className='section-text custom-container g-1 d-flex flex-column justify-content-center align-items-center'
				>
					{/* Main Title */}
					{content.title && (
						<Fade triggerOnce={true} direction='up' delay={20}>
							<Col
								dangerouslySetInnerHTML={{ __html: content.title }}
								className='title serif-font animate__animated animate__fadeInUp animate__delay-1s'
								style={{
									'--animate-delay': '0.75s',
								}}
							></Col>
						</Fade>
					)}

					{/* Main Description */}
					{content.description && (
						<Fade triggerOnce={true} direction='up' delay={40}>
							<Col
								dangerouslySetInnerHTML={{ __html: content.description }}
								className='description text-limit text-capitalize animate__animated animate__fadeInUp animate__delay-1s'
								style={{
									'--animate-delay': '1s',
									'--lines': 10,
								}}
							></Col>
						</Fade>
					)}
				</Row>
			</Container>
		</Container>
	);
};

export default HeroSectionComponent;
