export const isMultilingual = false;

export const routes = {
	fallback: isMultilingual ? '/ar' : '/',
	notFound: isMultilingual ? '/:lang/*' : '/*',

	home: isMultilingual ? '/:lang' : '/',

	about: isMultilingual ? '/:lang/about-us' : '/about-us',

	contact: isMultilingual ? '/:lang/contact-us' : '/contact-us',

	products: {
		root: isMultilingual ? '/:lang/products' : '/products',

		// ! NOT USED AT ALL
		// single: isMultilingual
		// 	? '/:lang/products/:product_id'
		// 	: '/products/:product_id',
	},
};
