import React from 'react';
import { Image } from 'react-bootstrap';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Styles
import 'swiper/css';
import './CertificatesComponent.styles.css';

const CertificatesComponent = ({ certificates, setLightbox }) => {
	return (
		<Swiper
			lang='en'
			dir='ltr'
			slidesPerView={2}
			spaceBetween={10}
			autoplay={{
				delay: 1000,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			}}
			loop={true}
			breakpoints={{
				576: {
					slidesPerView: 2,
					spaceBetween: 20,
				},
				992: {
					slidesPerView: 3,
					spaceBetween: 30,
				},
				1200: {
					slidesPerView: 4,
					spaceBetween: 30,
				},
			}}
			grabCursor={true}
			modules={[Autoplay]}
			className='certificates-component h-100'
		>
			{certificates.map((certificate, index) => (
				<SwiperSlide
					key={index}
					className='image-container h-100 d-flex justify-content-center align-items-center'
				>
					<Image
						src={certificate.image}
						alt='certificate image'
						className='certificate text-capitalize w-100 h-100'
						style={{
							objectFit: 'contain',
							objectPosition: 'center',
						}}
						onError={({ currentTarget }) => {
							currentTarget.onerror = null; // prevents looping
							currentTarget.src = require('./../../assets/images/logos/logo.png');
						}}
						onClick={() =>
							setLightbox({
								isOpen: true,
								index: index,
							})
						}
					/>
				</SwiperSlide>
			))}
		</Swiper>
	);
};

export default CertificatesComponent;
