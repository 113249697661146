import axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import {
	Badge,
	Col,
	Container,
	FormGroup,
	FormText,
	Row,
	Spinner,
} from 'react-bootstrap';
import { FaClipboardList } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { object, string } from 'yup';
import { BASE_URL } from './../../helpers/general';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { clearCart } from './../../store/slices/cart.reducer';

// i18next
import { useTranslation } from 'react-i18next';

// Styles
import './ContactFormComponent.styles.css';

// Components
import CartModalComponent from '../CartModalComponent/CartModalComponent';
import ButtonComponent from './../../components/ButtonComponent/ButtonComponent';

const ContactFormComponent = ({ postRoute = '/contact' }) => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'en');
		// eslint-disable-next-line
	}, [lang]);

	// Redux
	const dispatch = useDispatch();
	const cart = useSelector((state) => state.cart);

	// Refs
	const fullNameRef = useRef(null);
	const emailRef = useRef(null);
	const messageRef = useRef(null);

	// Schema
	const contactSchema = object().shape({
		fullName: string()
			.min(2, t('validations:fullName.min', { min: 2 }))
			.max(100, t('validations:fullName.max', { max: 100 }))
			.required(t('validations:fullName.required')),
		email: string()
			.email(t('validations:email.format'))
			.required(t('validations:email.required')),
		message: string()
			.min(4, t('validations:message.min', { min: 4 }))
			.max(500, t('validations:message.max', { max: 500 }))
			.required(t('validations:message.required')),
	});

	// Handle Form Errors
	const displayErrors = (fieldName) => {
		switch (fieldName) {
			case 'fullName':
				fullNameRef.current.classList.add('is-invalid');
				break;

			case 'email':
				emailRef.current.classList.add('is-invalid');
				break;

			case 'message':
				messageRef.current.classList.add('is-invalid');
				break;

			default:
				break;
		}
	};

	// Display Form Errors
	const displayToast = (statusCode, message) => {
		switch (statusCode) {
			case 200:
				toast.success(message, {
					toastId: message,
				});
				break;
			case 400:
				toast.error(message, {
					toastId: message,
				});
				break;
			default:
				toast.error(t('sentences:errors.default'));
				break;
		}
	};

	const submitContactForm = async (
		values,
		setSubmitting,
		resetForm,
		language = 'en'
	) => {
		axios({
			method: 'POST',
			baseURL: BASE_URL.demo,
			url: postRoute,
			data: {
				name: values.fullName,
				email: values.email,
				message: values.message,
				products: JSON.stringify(cart.map((product) => product.id)),
			},
			headers: { locale: language, 'Content-Type': 'multipart/form-data' },
		})
			.then((response) => {
				// reset form
				resetForm(true);

				// clear cart
				dispatch(clearCart());

				displayToast(response.status, response.data.message);
			})
			.catch((error) => {
				if (error.response.data.data !== {}) {
					Object.keys(error.response.data.data).forEach((key) => {
						displayErrors(key);
						displayToast(
							error.response.status,
							error.response.data.data[key][0]
						);
					});
				} else {
					displayToast(error.response.status, error.response.data.message);
				}
			})
			.finally(() => {
				// reset submitting
				setSubmitting(false);
			});
	};

	// Cart Modal Handlers
	const [showCartModal, setShowCartModal] = useState(false);
	const openCartModal = () => setShowCartModal(true);
	const closeCartModal = () => setShowCartModal(false);

	return (
		<Container
			fluid
			lang={lang ?? 'en'}
			dir={lang === 'ar' ? 'rtl' : 'ltr'}
			className='contact-form-component px-0'
		>
			{/* Contact Form */}
			<Row xs={1}>
				<Col className='d-flex justify-content-start align-items-start'>
					<Formik
						initialValues={{
							fullName: '',
							email: '',
							message: '',
						}}
						validationSchema={contactSchema}
						onSubmit={(values, { setSubmitting, resetForm }) => {
							setSubmitting(true);
							submitContactForm(values, setSubmitting, resetForm, lang ?? 'en');
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
							setFieldValue,
						}) => (
							<Form
								onSubmit={(event) => {
									event.preventDefault();
									handleSubmit();
								}}
								className='p-1 overflow-hidden'
							>
								<Fade triggerOnce={true} direction='left' delay={20}>
									<Row xs={1} className='g-4'>
										{/* Full Name */}
										<FormGroup as={Col}>
											<Field
												id='full_name'
												type='text'
												innerRef={fullNameRef}
												placeholder={t('words:placeholders.fullName')}
												autoComplete='off'
												name='fullName'
												onChange={(event) => {
													handleChange(event);
												}}
												onBlur={handleBlur}
												value={values.fullName}
												className={`form-control text-capitalize ${
													touched.fullName && errors.fullName
														? 'is-invalid'
														: ''
												}`}
											/>
											<ErrorMessage
												component='div'
												name='fullName'
												className='invalid-feedback'
											/>
										</FormGroup>

										{/* Email */}
										<FormGroup as={Col}>
											<Field
												id='email'
												type='email'
												innerRef={emailRef}
												placeholder={t('words:placeholders.email')}
												autoComplete='off'
												name='email'
												onChange={(event) => {
													handleChange(event);
												}}
												onBlur={handleBlur}
												value={values.email}
												className={`form-control ${
													touched.email && errors.email ? 'is-invalid' : ''
												}`}
											/>
											<ErrorMessage
												component='div'
												name='email'
												className='invalid-feedback'
											/>
										</FormGroup>

										{/* Message */}
										<FormGroup as={Col} className='position-relative'>
											<Field
												id='message'
												as='textarea'
												innerRef={messageRef}
												rows={8}
												style={{
													resize: 'none',
												}}
												placeholder={t('words:placeholders.message')}
												autoComplete='off'
												name='message'
												onChange={(event) => {
													handleChange(event);
												}}
												onBlur={handleBlur}
												value={values.message}
												className={`form-control text-capitalize ${
													touched.message && errors.message ? 'is-invalid' : ''
												}`}
											/>

											<ErrorMessage
												component='div'
												name='message'
												className='invalid-feedback'
											/>
										</FormGroup>

										{/* Submit Form */}
										<FormGroup className='d-flex justify-content-between align-items-center'>
											{/* Quotation Products */}
											<FormText
												onClick={openCartModal}
												className='cart-icon position-relative'
											>
												<span>
													<FaClipboardList size={24} />
												</span>
												<span>
													<Badge
														pill
														bg='light'
														text='dark'
														className='quotation-badge'
													>
														{cart.length}
													</Badge>
												</span>
											</FormText>

											{/* Cart Modal */}
											<CartModalComponent
												show={showCartModal}
												onHide={closeCartModal}
											/>

											{/* Submit Button */}
											<ButtonComponent
												text={
													isSubmitting
														? t('words:buttons.sending')
														: t('words:buttons.submitMessage')
												}
												icon={
													isSubmitting ? (
														<Spinner
															animation='grow'
															variant='light'
															size='sm'
															className={`${lang === 'ar' ? 'ms-2' : 'me-2'}`}
														/>
													) : (
														<></>
													)
												}
												styles={{
													button: {
														'--hover-bg-color': '#383633',
														'--hover-title-color': '#ffffff',
													},
												}}
												type='submit'
												disabled={isSubmitting ? true : false}
											/>
										</FormGroup>
									</Row>
								</Fade>
							</Form>
						)}
					</Formik>
				</Col>
			</Row>
		</Container>
	);
};

export default ContactFormComponent;
