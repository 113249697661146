import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { fetchAboutData } from './../../store/slices/aboutData.reducer';
import { fetchCategories } from './../../store/slices/categories.reducer';
import { fetchHomeData } from './../../store/slices/homeData.reducer';
import { fetchProducts } from './../../store/slices/products.reducer';
import { fetchSettingsData } from './../../store/slices/settingsData.reducer';

// Styles
import 'react-toastify/dist/ReactToastify.css';

// Components
import FooterComponent from '../../components/FooterComponent/FooterComponent';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import MetaTagsComponent from '../../components/MetaTagsComponent/MetaTagsComponent';
import NavbarComponent from '../../components/NavbarComponent/NavbarComponent';

const MainTemplatePage = ({ children }) => {
	// Handle Language Change
	const { lang } = useParams();

	// Redux
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(fetchHomeData(lang ?? 'en'));
		dispatch(fetchAboutData(lang ?? 'en'));
		dispatch(fetchProducts({ language: lang ?? 'en', searchParams: {} }));
		dispatch(fetchCategories(lang ?? 'en'));
		dispatch(fetchSettingsData(lang ?? 'en'));
		// eslint-disable-next-line
	}, [lang]);

	const { isSettingsLoading } = useSelector((state) => state.settingsData);
	const { isHomeDataLoading } = useSelector((state) => state.homeData);
	const { isProductsLoading } = useSelector((state) => state.products);
	const { isCategoriesLoading } = useSelector((state) => state.categories);
	const { isAboutDataLoading } = useSelector((state) => state.aboutData);

	// Scroll To Top On Initial Render
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, [lang]);

	return isSettingsLoading ||
		isHomeDataLoading ||
		isProductsLoading ||
		isCategoriesLoading ||
		isAboutDataLoading ? (
		<LoadingComponent />
	) : (
		<>
			{/* Meta Tags */}
			<MetaTagsComponent />

			{/* Page Navbar */}
			<NavbarComponent />

			{/* Current Page Content */}
			{children}

			{/* Page Footer */}
			<FooterComponent />

			{/* Toast Messages */}
			<ToastContainer
				position='top-right'
				autoClose={6000}
				newestOnTop
				pauseOnHover
				rtl={lang === 'ar' ? true : false}
			/>
		</>
	);
};

export default MainTemplatePage;
