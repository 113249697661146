import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

// Styles
import './LoadingComponent.styles.css';

// Components

const LoadingComponent = () => {
	const { lang } = useParams();

	return (
		<Container lang='en' dir='ltr' id='loading-component' fluid>
			<Row xs={1}>
				<Col className='d-flex justify-content-center align-items-center w-100'>
					<Image
						fluid
						src={require('./../../assets/images/loader.gif')}
						alt='company logo'
						className='text-capitalize'
						style={{
							objectFit: 'contain',
							objectPosition: 'center',
						}}
						onError={({ currentTarget }) => {
							currentTarget.onerror = null; // prevents looping
							currentTarget.src = require('./../../assets/images/logos/logo.png');
						}}
					/>
				</Col>
			</Row>
		</Container>
	);
};

export default LoadingComponent;
