import React, { useEffect } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Autoplay, EffectFade, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import './MainSliderComponent.styles.css';

// Components

const MainSliderComponent = ({ sliders }) => {
	// i18next
	const { lang } = useParams();
	const { i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'en');
		// eslint-disable-next-line
	}, [lang]);

	return (
		sliders.length > 0 && (
			<Swiper
				lang='en'
				dir='ltr'
				key={lang}
				slidesPerView={1}
				spaceBetween={0}
				loop={true}
				autoplay={{
					delay: 8000,
					disableOnInteraction: false,
					pauseOnMouseEnter: true,
				}}
				grabCursor={true}
				effect='fade'
				pagination={{
					clickable: true,
				}}
				modules={[Pagination, Autoplay, EffectFade]}
				className='main-slider-component'
			>
				{sliders.map((slide, index) => (
					// Render slides
					<SwiperSlide
						key={index}
						className='slider-container position-relative'
					>
						{/* Slider Image */}
						<Image
							fluid
							key={slide}
							src={slide.image}
							alt='slider image'
							className='text-capitalize w-100 h-100'
							style={{
								objectFit: 'cover',
								objectPosition: 'center',
							}}
							onError={({ currentTarget }) => {
								currentTarget.onerror = null; // prevents looping
								currentTarget.src = require('./../../assets/images/logos/logo.png');
							}}
						/>

						{/* Slider Text */}
						{(slide.title || slide.description) && (
							<Container className='text-container overflow-hidden'>
								<Row
									xs={1}
									className='slider-text custom-container g-1 d-flex flex-column justify-content-center align-items-center'
								>
									{/* Main Title */}
									{slide.title && (
										<Col
											dangerouslySetInnerHTML={{ __html: slide.title }}
											className='title serif-font animate__animated animate__fadeInUp animate__delay-1s'
											style={{
												'--animate-delay': '0.75s',
											}}
										></Col>
									)}

									{/* Main Description */}
									{slide.description && (
										<Col
											dangerouslySetInnerHTML={{ __html: slide.description }}
											className='description text-limit text-capitalize animate__animated animate__fadeInUp animate__delay-1s'
											style={{
												'--animate-delay': '1s',
												'--lines': 10,
											}}
										></Col>
									)}
								</Row>
							</Container>
						)}
					</SwiperSlide>
				))}
			</Swiper>
		)
	);
};

export default MainSliderComponent;
