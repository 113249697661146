import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';

// i18next
import { useTranslation } from 'react-i18next';

// Styles
import './AboutPage.styles.css';

// Components
import HeroSectionComponent from './../../components/HeroSectionComponent/HeroSectionComponent';
import MetaTagsComponent from './../../components/MetaTagsComponent/MetaTagsComponent';
import PartnersComponent from './../../components/PartnersComponent/PartnersComponent';
import SeparatorComponent from './../../components/SeparatorComponent/SeparatorComponent';

const AboutPage = () => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'en');
		// eslint-disable-next-line
	}, [lang]);

	// Redux
	const { whyUs, whatWeOffer, whoAreWe, mission, vision, partners } =
		useSelector((state) => state.aboutData);

	// Scroll To Top On Initial Render
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, [lang]);

	return (
		<Container
			fluid
			lang='en'
			dir='ltr'
			id='about-us-page'
			className='page overflow-hidden'
		>
			{/* MetaTags */}
			<MetaTagsComponent pageTitle={t('words:windowTab.aboutUs')} />

			{/* Who Are We */}
			<HeroSectionComponent content={whoAreWe} className='landing' />

			{/* Why Us */}
			<SeparatorComponent content={whyUs} id='why-us' hasSerifFont={true} />

			{/* What We Offer */}
			<SeparatorComponent
				content={whatWeOffer}
				id='what-we-offer'
				hasSerifFont={true}
			/>

			{/* Our Vision */}
			<HeroSectionComponent content={vision} className='vision' />

			{/* Our Mission */}
			<SeparatorComponent
				content={mission}
				id='always-there'
				hasSerifFont={true}
			/>

			{/* Partners */}
			<PartnersComponent partners={partners} />
		</Container>
	);
};

export default AboutPage;
