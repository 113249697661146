import axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import {
	Col,
	Container,
	FormGroup,
	Image,
	ListGroup,
	Row,
	Spinner,
} from 'react-bootstrap';
import { BsEnvelope, BsPhone, BsWhatsapp } from 'react-icons/bs';
import { FiPhoneCall } from 'react-icons/fi';
import { MdOutlineLocationOn } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link, NavLink, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { object, string } from 'yup';
import { BASE_URL, checkURL } from './../../helpers/general';
import { routes } from './../../routes/index.routes';

// i18n
import { useTranslation } from 'react-i18next';

// Styles
import './FooterComponent.styles.css';

// Components
import CertificatesComponent from '../CertificatesComponent/CertificatesComponent';
import ButtonComponent from './../ButtonComponent/ButtonComponent';
import IconTextComponent from './../IconTextComponent/IconTextComponent';
import LightboxComponent from './../LightboxComponent/LightboxComponent';

const FooterComponent = () => {
	// i18n
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'en');
		// eslint-disable-next-line
	}, [lang]);

	// States
	const [lightbox, setLightbox] = useState({ isOpen: false, index: 0 });

	const ICON = { SIZE: 24 };

	// Redux
	const {
		settings: { logo, footer_description, address, copyrights },
		contacts: { mobile, telephone, email, social, whatsapp },
		certificates,
	} = useSelector((state) => state.settingsData);

	// Refs
	const emailRef = useRef(null);

	// Schema
	const newsletterSchema = object().shape({
		email: string()
			.email(t('validations:email.format'))
			.required(t('validations:email.required')),
	});

	// Handle Form Errors
	const displayErrors = (fieldName) => {
		switch (fieldName) {
			case 'email':
				emailRef.current.classList.add('is-invalid');
				break;

			default:
				break;
		}
	};

	// Display Form Errors
	const displayToast = (statusCode, message) => {
		switch (statusCode) {
			case 200:
				toast.success(message, {
					toastId: message,
				});
				break;
			case 400:
				toast.error(message, {
					toastId: message,
				});
				break;
			default:
				toast.error(t('sentences:errors.default'));
				break;
		}
	};

	const submitNewsletterForm = async (
		values,
		setSubmitting,
		resetForm,
		language = 'en'
	) => {
		axios({
			method: 'POST',
			baseURL: BASE_URL.demo,
			url: '/newsletter',
			data: {
				email: values.email,
			},
			headers: { locale: language, 'Content-Type': 'multipart/form-data' },
		})
			.then((response) => {
				// reset form
				resetForm(true);

				displayToast(response.status, response.data.message);
			})
			.catch((error) => {
				console.log(error);
				if (error.response.data.data !== {}) {
					Object.keys(error.response.data.data).forEach((key) => {
						displayErrors(key);
						displayToast(
							error.response.status,
							error.response.data.data[key][0]
						);
					});
				} else {
					displayToast(error.response.status, error.response.data.message);
				}
			})
			.finally(() => {
				// reset submitting
				setSubmitting(false);
			});
	};

	return (
		<Container
			as='footer'
			fluid
			lang='en'
			dir='ltr'
			id='footer-component'
			className='position-relative px-0'
		>
			<Container className='custom-container position-relative'>
				{/* Logo */}
				<Row>
					<Col className='logo-container'>
						<Link to={routes.home}>
							<Image
								fluid
								src={logo}
								alt='company logo'
								className='text-capitalize w-100 h-100'
								style={{
									objectFit: 'contain',
									objectPosition: 'center',
								}}
								onError={({ currentTarget }) => {
									currentTarget.onerror = null; // prevents looping
									currentTarget.src = require('./../../assets/images/logos/logo.png');
								}}
							/>
						</Link>
					</Col>
				</Row>

				<Row className='g-4'>
					{/* Contact Info */}
					<Col xs={12} sm={7} md={6} xl={4}>
						<Row className='text-container g-4'>
							{/* Address */}
							<Col xs={12} className='d-flex flex-column'>
								<h3 className='title'>{t('words:address')}</h3>

								<IconTextComponent
									icon={<MdOutlineLocationOn />}
									text={address}
									isCentered={false}
									styles={{
										container: {
											'--size': ICON.SIZE,
											marginBottom: '0.5rem',
										},
									}}
								/>
							</Col>

							<Col xs={12} className='d-flex flex-column'>
								<h3 className='title'>{t('words:contact')}</h3>

								{/* Telephones */}
								{telephone.length > 0 &&
									telephone.map((telephone, index) => (
										<IconTextComponent
											key={index}
											icon={<FiPhoneCall />}
											text={
												<Container
													fluid
													className='value p-0'
													style={{
														direction: 'ltr',
													}}
												>
													<a href={`tel:${telephone.contact}`}>
														{telephone.contact}
													</a>
												</Container>
											}
											isCentered={false}
											styles={{
												container: {
													'--size': ICON.SIZE,
													marginBottom: '0.5rem',
												},
											}}
										/>
									))}

								{/* Phones */}
								{mobile.length > 0 &&
									mobile.map((mobile, index) => (
										<IconTextComponent
											key={index}
											icon={<BsPhone />}
											text={
												<Container
													fluid
													className='value p-0'
													style={{
														direction: 'ltr',
													}}
												>
													<a href={`tel:${mobile.contact}`}>{mobile.contact}</a>
												</Container>
											}
											isCentered={false}
											styles={{
												container: {
													'--size': ICON.SIZE,
													marginBottom: '0.5rem',
												},
											}}
										/>
									))}

								{/* Whatsapp Number */}
								{whatsapp.length &&
									whatsapp.map((whatsapp, index) => (
										<IconTextComponent
											key={index}
											icon={<BsWhatsapp />}
											text={
												<Container
													fluid
													className='value p-0'
													style={{
														direction: 'ltr',
													}}
												>
													<a
														href={`https://wa.me/${whatsapp.contact}`}
														target='_blank'
														rel='noreferrer'
													>
														{whatsapp.contact}
													</a>
												</Container>
											}
											isCentered={false}
											styles={{
												container: {
													'--size': ICON.SIZE,
													marginBottom: '0.5rem',
												},
											}}
										/>
									))}

								{/* Emails */}
								{email.length > 0 &&
									email.map((email, index) => (
										<IconTextComponent
											key={index}
											icon={<BsEnvelope />}
											text={
												<Container fluid key={index} className='value p-0'>
													<a href={`mailto:${email.contact}`}>
														{email.contact}
													</a>
												</Container>
											}
											isCentered={false}
											isCapitalized={false}
											styles={{
												container: {
													'--size': ICON.SIZE,
												},
											}}
										/>
									))}
							</Col>

							<Col sx={12} className='social-accounts d-flex flex-column'>
								<h3 className='title'>{t('words:socials')}</h3>

								{/* Socials */}
								{social.length > 0 && (
									<div className='d-flex align-items-center pt-2'>
										{social.map((social, index) => (
											<a
												key={index}
												href={checkURL(social.contact)}
												target='_blank'
												rel='noreferrer'
												className={`icon ${social.icon}`}
												style={{
													'--icon-color': social.contact.includes('facebook')
														? '#1877f2'
														: social.contact.includes('twitter')
														? '#1da1f2'
														: social.contact.includes('linkedin')
														? '#0077b5'
														: social.contact.includes('instagram')
														? '#f56040'
														: social.contact.includes('youtube')
														? '#ff0000'
														: '#34bf49',
												}}
											>
												{''}
											</a>
										))}
									</div>
								)}
							</Col>
						</Row>
					</Col>

					{/* Important Links */}
					<Col xs={12} sm={1} md={1} xl={1}></Col>
					<Col xs={12} sm={4} md={5} xl={2}>
						<Row className='text-container'>
							<Col className='d-flex flex-column'>
								<h3 className='title'>{t('words:footer.headers.links')}</h3>
								<ListGroup as='ul'>
									{/* Home */}
									<ListGroup.Item as='li'>
										<NavLink to={routes.home} end>
											{t('words:footer.home')}
										</NavLink>
									</ListGroup.Item>

									{/* About */}
									<ListGroup.Item as='li'>
										<NavLink to={routes.about}>
											{t('words:footer.aboutUs')}
										</NavLink>
									</ListGroup.Item>

									{/* Products */}
									<ListGroup.Item as='li'>
										<NavLink to={routes.products.root}>
											{t('words:footer.products')}
										</NavLink>
									</ListGroup.Item>

									{/* Contact */}
									<ListGroup.Item as='li'>
										<NavLink to={routes.contact}>
											{t('words:footer.contactUs')}
										</NavLink>
									</ListGroup.Item>
								</ListGroup>
							</Col>
						</Row>
					</Col>
					<Col xs={12} xl={1}></Col>

					<Col xs={12} sm={12} xl={4}>
						<Row xs={1} className='g-4'>
							{/* Newsletter */}
							<Col className='newsletter-container'>
								<h3 className='title text-capitalize'>
									{t('sentences:newsletter')}
								</h3>

								<Formik
									initialValues={{
										email: '',
									}}
									validationSchema={newsletterSchema}
									onSubmit={(values, { setSubmitting, resetForm }) => {
										setSubmitting(true);
										submitNewsletterForm(
											values,
											setSubmitting,
											resetForm,
											lang ?? 'en'
										);
									}}
								>
									{({
										values,
										errors,
										touched,
										handleChange,
										handleBlur,
										handleSubmit,
										isSubmitting,
										setFieldValue,
									}) => (
										<Form
											onSubmit={(event) => {
												event.preventDefault();
												handleSubmit();
											}}
											className=''
										>
											<Row className='d-flex flex-column justify-content-center align-items-center'>
												{/* Email */}
												<FormGroup as={Col}>
													<Field
														id='email'
														type='email'
														innerRef={emailRef}
														placeholder={t('words:placeholders.email')}
														autoComplete='off'
														name='email'
														onChange={(event) => {
															handleChange(event);
														}}
														onBlur={handleBlur}
														value={values.email}
														className={`form-control ${
															touched.email && errors.email ? 'is-invalid' : ''
														}`}
													/>
													<ErrorMessage
														component='div'
														name='email'
														className='invalid-feedback'
													/>
												</FormGroup>

												{/* Submit Form */}
												<FormGroup
													className='mt-2'
													style={{ width: 'fit-content' }}
												>
													<ButtonComponent
														text={
															isSubmitting
																? t('words:buttons.sending')
																: t('words:buttons.subscribeNewsletter')
														}
														icon={
															isSubmitting ? (
																<Spinner
																	animation='grow'
																	variant='light'
																	size='sm'
																	className={`${
																		lang === 'ar' ? 'ms-2' : 'me-2'
																	}`}
																/>
															) : (
																<></>
															)
														}
														styles={{
															button: {
																'--hover-bg-color': '#383633',
																'--hover-title-color': '#ffffff',
															},
														}}
														type='submit'
														disabled={isSubmitting ? true : false}
													/>
												</FormGroup>
											</Row>
										</Form>
									)}
								</Formik>

								{/* Footer Description */}
								<Col
									dangerouslySetInnerHTML={{
										__html: footer_description,
									}}
									className='footer-description text-container'
								></Col>
							</Col>

							{/* Certificates */}
							<Col className='certificates-container text-container'>
								{/* Title */}
								<h3 className='title'>
									{t('words:footer.headers.certificates')}
								</h3>

								<CertificatesComponent
									certificates={certificates}
									setLightbox={setLightbox}
								/>

								{/* Lightbox */}
								<LightboxComponent
									gallery={certificates}
									pathname={'<object>.image'}
									lightbox={lightbox}
									setLightbox={setLightbox}
								/>
							</Col>
						</Row>
					</Col>

					{/* Copyrights */}
					<Col xs={12} id='copyrights' className='text-container text-center'>
						{copyrights}
					</Col>
				</Row>
			</Container>
		</Container>
	);
};

export default FooterComponent;
