import React, { useEffect, useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import { Col, Container, Form, Nav, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { filterByCategory } from './../../helpers/general';

// Redux
import { useSelector } from 'react-redux';

// i18next
import { useTranslation } from 'react-i18next';

// Styles
import './ProductsPage.styles.css';

// Components
import ContactFormComponent from './../../components/ContactFormComponent/ContactFormComponent';
import HeroSectionComponent from './../../components/HeroSectionComponent/HeroSectionComponent';
import MetaTagsComponent from './../../components/MetaTagsComponent/MetaTagsComponent';
import PartnersComponent from './../../components/PartnersComponent/PartnersComponent';
import ProductCardComponent from './../../components/ProductCardComponent/ProductCardComponent';
import SeeMoreButtonComponent from './../../components/SeeMoreButtonComponent/SeeMoreButtonComponent';
import SeparatorComponent from './../../components/SeparatorComponent/SeparatorComponent';

const ProductsPage = () => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'en');
		// eslint-disable-next-line
	}, [lang]);

	// Redux
	const { separators, partners } = useSelector((state) => state.homeData);
	const {
		pages: { productsHero, productsMainTitle },
	} = useSelector((state) => state.settingsData);
	const { products } = useSelector((state) => state.products);
	const { categories } = useSelector((state) => state.categories);

	// States
	const [filteredProducts, setFilteredProducts] = useState(products);
	const [category, setCategory] = useState(t('words:all'));
	const [currentProductsCount, setCurrentProductsCount] = useState(12);

	// Scroll To Top On Initial Render
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, [lang]);

	return (
		<Container
			fluid
			lang='en'
			dir='ltr'
			id='products-page'
			className='page overflow-hidden'
		>
			{/* MetaTags */}
			<MetaTagsComponent pageTitle={t('words:windowTab.products')} />

			{/* Hero Section */}
			<HeroSectionComponent content={productsHero} />

			{/* Products */}
			<Container fluid className='products-container position-relative'>
				<Container className='custom-container'>
					{/* Section Header */}
					<Row xs={1} className='header g-4'>
						{/* Title */}
						<Fade triggerOnce={true} direction='left' delay={20}>
							<Col
								dangerouslySetInnerHTML={{ __html: productsMainTitle.title }}
								className='title serif-font'
							></Col>
						</Fade>

						{/* Description */}
						<Fade triggerOnce={true} direction='left' delay={40}>
							<Col
								dangerouslySetInnerHTML={{
									__html: productsMainTitle.description,
								}}
								className='description'
							></Col>
						</Fade>
					</Row>

					{/* Products Search & Filter */}
					<Row xs={1} sm={2} className='g-4 mb-4'>
						{/* Search */}
						<Col className='products-filter'>
							<Form>
								<Form.Group>
									<Form.Label
										htmlFor='products-filter'
										className='text-capitalize'
									>
										{t('words:labels.filter')}
									</Form.Label>
									<Form.Control
										id='products-filter'
										className='text-capitalize'
										type='text'
										name='productsFilter'
										placeholder={t('words:placeholders.filter')}
										onChange={(event) =>
											setFilteredProducts(
												products.filter(
													(product) =>
														product.title
															.toLowerCase()
															.trim()
															.includes(
																event.target.value.toLowerCase().trim()
															) ||
														product.code
															.toLowerCase()
															.trim()
															.includes(event.target.value.toLowerCase().trim())
												)
											)
										}
									/>
								</Form.Group>
							</Form>
						</Col>

						{/* Category Filter */}
						<Col className='categories-filter'>
							<Nav
								defaultActiveKey={t('words:all')}
								onSelect={(selectedKey) => {
									setCurrentProductsCount(12);
									setCategory(selectedKey);
								}}
							>
								{/* All Categories */}
								<Nav.Item>
									<Nav.Link
										className='text-uppercase'
										eventKey={t('words:all')}
									>
										{t('words:all')}
									</Nav.Link>
								</Nav.Item>

								{categories.map((category, index) => (
									<Nav.Item key={index}>
										<Nav.Link
											className='text-uppercase'
											eventKey={category.title.toLowerCase().trim()}
										>
											{category.title}
										</Nav.Link>
									</Nav.Item>
								))}
							</Nav>
						</Col>
					</Row>

					{/* Products */}
					{filteredProducts.filter((product, index) => {
						return filterByCategory(
							category,
							product.category,
							index,
							currentProductsCount,
							t('words:all')
						);
					}).length > 0 ? (
						<Row
							xs={1}
							md={2}
							xxl={3}
							className='products-result g-4 position-relative'
						>
							{filteredProducts
								.filter((product, index) => {
									return filterByCategory(
										category,
										product.category,
										index,
										currentProductsCount,
										t('words:all')
									);
								})
								.filter((_, index) => index < currentProductsCount)
								.map((product, index) => (
									<Fade
										triggerOnce={true}
										key={index}
										direction='up'
										delay={index * 20}
									>
										<Col>
											<ProductCardComponent product={product} />
										</Col>
									</Fade>
								))}

							{/* See More Button */}
							<Container fluid className='see-more-btn px-0'>
								<Fade triggerOnce={true} direction='right' delay={100}>
									<SeeMoreButtonComponent
										disabled={
											currentProductsCount >=
											filteredProducts.filter((product, index) => {
												return filterByCategory(
													category,
													product.category,
													index,
													currentProductsCount,
													t('words:all')
												);
											}).length
												? true
												: false
										}
										onClick={() => {
											setCurrentProductsCount(currentProductsCount + 4);
											if (
												currentProductsCount <
												filteredProducts.filter((product, index) => {
													return filterByCategory(
														category,
														product.category,
														index,
														currentProductsCount,
														t('words:all')
													);
												}).length
											) {
												window.scrollBy({
													behavior: 'smooth',
													top: window.innerHeight / 2.5,
													left: 0,
												});
											}
										}}
									/>
								</Fade>
							</Container>
						</Row>
					) : (
						<Row xs={1}>
							{/* No Results Available */}
							<Fade triggerOnce={true} direction='up' delay={20}>
								<Col className='error'>
									{t('sentences:errors.noData', { title: t('words:products') })}
								</Col>
							</Fade>
						</Row>
					)}
				</Container>
			</Container>

			{/* Separator #1 - We Are Always There */}
			<SeparatorComponent
				content={separators.alwaysThere}
				id='always-there'
				hasSerifFont={true}
			/>

			{/* Partners */}
			<PartnersComponent partners={partners} />

			{/* Get In Touch */}
			<Container fluid className='contact-container position-relative'>
				<Container className='custom-container'>
					{/* Section Header */}
					<Row xs={1} className='header g-4'>
						{/* Title */}
						<Fade triggerOnce={true} direction='left' delay={20}>
							<Col
								dangerouslySetInnerHTML={{ __html: separators.contact.title }}
								className='title serif-font'
							></Col>
						</Fade>

						{/* Description */}
						<Fade triggerOnce={true} direction='left' delay={40}>
							<Col
								dangerouslySetInnerHTML={{
									__html: separators.contact.description,
								}}
								className='description'
							></Col>
						</Fade>
					</Row>

					{/* Content */}
					<Row xs={1} className='g-4'>
						{/* Contact Form */}
						<Col>
							<ContactFormComponent postRoute='/quotation' />
						</Col>
					</Row>
				</Container>
			</Container>
		</Container>
	);
};

export default ProductsPage;
