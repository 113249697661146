import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import Marquee from 'react-fast-marquee';
import { useParams } from 'react-router-dom';

// Styles
import './PartnersComponent.styles.css';

// Components

const PartnersComponent = ({ partners }) => {
	const { lang } = useParams();

	return (
		partners.length > 0 && (
			<Container
				lang='en'
				dir='ltr'
				// fluid
				className='partners-slider-component'
			>
				<Row xs={1} className='custom-container'>
					{/* Main Content */}
					<Col className='px-0'>
						<Marquee
							direction='left'
							gradient={false}
							gradientColor={[248, 251, 253]}
							gradientWidth={100}
							pauseOnHover={true}
							speed={100}
							className='partners-container overflow-hidden'
						>
							{partners.map((partner, index) => (
								<Image
									fluid
									key={index}
									src={partner.image}
									alt='partner image'
									className='text-capitalize mx-5 w-100 h-100'
									style={{
										objectFit: 'contain',
										objectPosition: 'center',
									}}
									onError={({ currentTarget }) => {
										currentTarget.onerror = null; // prevents looping
										currentTarget.src = require('./../../assets/images/logos/logo.png');
									}}
								/>
							))}
							{partners.map((partner, index) => (
								<Image
									fluid
									key={index}
									src={partner.image}
									alt='partner image'
									className='text-capitalize mx-5 w-100 h-100'
									style={{
										objectFit: 'contain',
										objectPosition: 'center',
									}}
									onError={({ currentTarget }) => {
										currentTarget.onerror = null; // prevents looping
										currentTarget.src = require('./../../assets/images/logos/logo.png');
									}}
								/>
							))}
						</Marquee>
					</Col>
				</Row>
			</Container>
		)
	);
};

export default PartnersComponent;
