import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from './../../helpers/general';

export const fetchHomeData = createAsyncThunk(
	'homeDataSlice/fetchHomeData',
	async (language = 'en', { rejectWithValue }) => {
		return await axios({
			method: 'GET',
			baseURL: BASE_URL.demo,
			url: '/home',
			headers: {
				locale: language,
			},
		})
			.then((response) => {
				return response.data.data;
			})
			.catch((error) => rejectWithValue(error.message));
	}
);

export const homeDataSlice = createSlice({
	initialState: {
		sliders: [],
		separators: {},
		partners: [],
		isHomeDataLoading: true,
	},
	name: 'homeDataSlice',
	reducers: {},
	extraReducers: (builder) => {
		// Pending
		builder.addCase(fetchHomeData.pending, (state, action) => {
			state.isHomeDataLoading = true;
		});

		// Fulfilled
		builder.addCase(fetchHomeData.fulfilled, (state, action) => {
			state.sliders = action.payload.sliders;
			state.separators = {
				whyUs: action.payload['pages'][0]['why_buy_mse'],
				products: action.payload['pages'][1]['home_product_page'],
				alwaysThere: action.payload['pages'][2]['always_there'],
				contact: action.payload['pages'][3]['get_in_touch'],
				ourVision: action.payload['pages'][4]['our_vision'],
			};
			state.partners = action.payload.partners;

			state.isHomeDataLoading = false;
		});

		// Rejected
		builder.addCase(fetchHomeData.rejected, (state, action) => {
			console.log('rejected:', action.payload);
			state.isHomeDataLoading = true;
		});
	},
});

// Actions
// eslint-disable-next-line
export const {} = homeDataSlice.actions;

// Reducer
export default homeDataSlice.reducer;
