import React from 'react';
import { Card, Container } from 'react-bootstrap';

// Styles
import './ProductHomeCardComponent.styles.css';

const ProductHomeCardComponent = ({ product }) => {
	return (
		<Container
			lang='en'
			dir='ltr'
			fluid
			className='product-home-card-component px-0'
		>
			<Card className='product-home-card-container'>
				<Card.Img
					fluid='true'
					variant='top'
					src={product.image}
					alt='product image'
					className='text-capitalize w-100 h-100'
					style={{
						objectFit: 'contain',
						objectPosition: 'center',
					}}
					onError={({ currentTarget }) => {
						currentTarget.onerror = null; // prevents looping
						currentTarget.src = require('./../../assets/images/logos/logo.png');
					}}
				/>
				<Card.Body className='text-container position-relative'>
					<Card.Title
						className='title position-relative text-limit'
						style={{ '--lines': 1 }}
					>
						{product.title}
					</Card.Title>
					<Card.Text
						className='description text-limit'
						style={{ '--lines': 2 }}
						dangerouslySetInnerHTML={{ __html: product.description }}
					></Card.Text>
				</Card.Body>
			</Card>
		</Container>
	);
};

export default ProductHomeCardComponent;
