import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// Styles
import './ButtonComponent.styles.css';

// Components

const ButtonComponent = ({
	text = '',
	icon = null,
	link = null,
	type = 'button',
	disabled = false,
	styles = {},
	onClick = () => {},
	...props
}) => {
	return (
		<Button
			type={type}
			as={link ? Link : 'button'}
			to={link}
			className={`d-flex justify-content-center align-items-center ${
				props.className ? props.className : ''
			} ${disabled ? 'disabled' : ''}`}
			style={{
				...styles.button,
			}}
			onClick={onClick}
		>
			{/* Icon */}
			{icon && (
				<span
					style={{
						...styles.icon,
					}}
				>
					{icon}
				</span>
			)}

			{/* Text */}
			{text && (
				<span
					className='text-capitalize'
					style={{
						...styles.text,
					}}
				>
					{text}
				</span>
			)}
		</Button>
	);
};

export default ButtonComponent;
