import React, { useEffect, useState } from 'react';
import {
	Badge,
	Container,
	Image,
	Nav,
	Navbar,
	Offcanvas,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaClipboardList } from 'react-icons/fa';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { routes } from './../../routes/index.routes';

// Redux
import { useSelector } from 'react-redux';

// Styles
import './NavbarComponent.styles.css';

// Components
import CartModalComponent from './../CartModalComponent/CartModalComponent';

const NavbarComponent = () => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'en');
		// eslint-disable-next-line
	}, [lang]);

	// Change Displayed Language
	const location = useLocation();
	const navigate = useNavigate();

	// States
	const [isExpanded, setIsExpanded] = useState(false);
	const [changeBackground, setChangeBackground] = useState(false);
	window.addEventListener('scroll', () => {
		window.scrollY > 0.1 * window.innerHeight
			? setChangeBackground(true)
			: setChangeBackground(false);
	});

	// Redux
	const {
		settings: { logo },
	} = useSelector((state) => state.settingsData);
	const cart = useSelector((state) => state.cart);

	// Navbar Handlers
	const toggleNavbar = () => {
		setIsExpanded(!isExpanded);
	};
	const handleClose = () => setIsExpanded(false);

	// Cart Modal Handlers
	const [showCartModal, setShowCartModal] = useState(false);
	const openCartModal = () => setShowCartModal(true);
	const closeCartModal = () => setShowCartModal(false);

	return (
		<Navbar
			lang='en'
			dir='ltr'
			id='navbar-component'
			// bg='light'
			// variant='light'
			fixed='top'
			expand='lg'
			expanded={isExpanded}
			style={{
				backgroundColor: changeBackground
					? 'rgba(29, 46, 64, 1)'
					: 'transparent',
			}}
		>
			<Container className='custom-container'>
				{/* Navbar Logo */}
				<Navbar.Brand
					as={NavLink}
					to={routes.home}
					onClick={handleClose}
					className='m-0'
				>
					<Image
						fluid
						src={logo}
						alt='company logo'
						className='text-capitalize'
						onError={({ currentTarget }) => {
							currentTarget.onerror = null; // prevents looping
							currentTarget.src = require('./../../assets/images/logos/logo.png');
						}}
					/>
				</Navbar.Brand>

				{/* Navbar Toggler */}
				<Navbar.Toggle
					aria-controls='offcanvasNavbar-expand'
					onClick={toggleNavbar}
				/>

				{/* Navbar Menu */}
				<Navbar.Offcanvas
					lang='en'
					dir='ltr'
					id='offcanvasNavbar-expand'
					aria-labelledby='offcanvasNavbar-expand'
					placement='end'
					className='bg-light'
					restoreFocus={false}
					show={isExpanded}
					onHide={handleClose}
				>
					<Offcanvas.Header closeButton>
						<Offcanvas.Title id='offcanvasNavbarLabel-expand'>
							<Navbar.Brand
								as={NavLink}
								to={routes.home}
								onClick={handleClose}
								className='m-0'
							>
								<Image
									fluid
									src={logo}
									alt='company logo'
									className='text-capitalize'
									onError={({ currentTarget }) => {
										currentTarget.onerror = null; // prevents looping
										currentTarget.src = require('./../../assets/images/logos/logo.png');
									}}
								/>
							</Navbar.Brand>
						</Offcanvas.Title>
					</Offcanvas.Header>
					<Offcanvas.Body>
						<Nav className='ms-auto text-uppercase'>
							{/* Home */}
							<Nav.Link as={NavLink} to={routes.home} end onClick={handleClose}>
								{t('words:navbar.home')}
							</Nav.Link>

							{/* About Us */}
							<Nav.Link as={NavLink} to={routes.about} onClick={handleClose}>
								{t('words:navbar.aboutUs')}
							</Nav.Link>

							{/* Products */}
							<Nav.Link
								as={NavLink}
								to={routes.products.root}
								onClick={handleClose}
							>
								{t('words:navbar.products')}
							</Nav.Link>

							{/* Contact Us */}
							<Nav.Link as={NavLink} to={routes.contact} onClick={handleClose}>
								{t('words:navbar.contactUs')}
							</Nav.Link>

							{/* Cart Icon */}
							<Navbar.Text
								onClick={openCartModal}
								className='cart-icon position-relative'
							>
								<span>
									<FaClipboardList size={24} />
								</span>
								<span>
									<Badge
										pill
										bg='light'
										text='dark'
										className='quotation-badge'
									>
										{cart.length}
									</Badge>
								</span>
							</Navbar.Text>

							{/* Cart Modal */}
							<CartModalComponent
								show={showCartModal}
								onHide={closeCartModal}
							/>
						</Nav>
					</Offcanvas.Body>
				</Navbar.Offcanvas>
			</Container>
		</Navbar>
	);
};

export default NavbarComponent;
