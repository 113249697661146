import { configureStore } from '@reduxjs/toolkit';

// Slices
import aboutDataReducer from './slices/aboutData.reducer';
import cartReducer from './slices/cart.reducer';
import categoriesReducer from './slices/categories.reducer';
import homeDataReducer from './slices/homeData.reducer';
import productsReducer from './slices/products.reducer';
import settingsDataReducer from './slices/settingsData.reducer';

export const store = configureStore({
	reducer: {
		homeData: homeDataReducer,
		products: productsReducer,
		categories: categoriesReducer,
		aboutData: aboutDataReducer,
		settingsData: settingsDataReducer,
		cart: cartReducer,
	},

	devTools: false,
});
