import React, { useEffect, useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';

// Styles
import './HomePage.styles.css';

// Components
import ContactFormComponent from '../../components/ContactFormComponent/ContactFormComponent';
import HeroSectionComponent from './../../components/HeroSectionComponent/HeroSectionComponent';
import MainSliderComponent from './../../components/MainSliderComponent/MainSliderComponent';
import MetaTagsComponent from './../../components/MetaTagsComponent/MetaTagsComponent';
import PartnersComponent from './../../components/PartnersComponent/PartnersComponent';
import ProductHomeCardComponent from './../../components/ProductHomeCardComponent/ProductHomeCardComponent';
import SeeMoreButtonComponent from './../../components/SeeMoreButtonComponent/SeeMoreButtonComponent';
import SeparatorComponent from './../../components/SeparatorComponent/SeparatorComponent';

const HomePage = () => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'en');
		// eslint-disable-next-line
	}, [lang]);

	// States
	const [currentProductsCount, setCurrentProductsCount] = useState(5);

	// Redux
	const { sliders, separators, partners } = useSelector(
		(state) => state.homeData
	);
	const { products } = useSelector((state) => state.products);

	// Scroll To Top On Initial Render
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, [lang]);

	return (
		<Container
			lang='en'
			dir='ltr'
			fluid
			id='home-page'
			className='page overflow-hidden'
		>
			{/* MetaTags */}
			<MetaTagsComponent pageTitle={t('words:windowTab.home')} />

			{/* Main Slider */}
			<MainSliderComponent sliders={sliders} />

			{/* Separator #1 - Why Buy From MSE */}
			<SeparatorComponent
				content={separators.whyUs}
				id='why-us'
				hasSerifFont={true}
			/>

			{/* Products */}
			<Container fluid className='products-container'>
				<Container className='custom-container'>
					{/* Section Header */}
					<Row xs={1} className='header g-4'>
						{/* Title */}
						<Fade triggerOnce={true} direction='left' delay={20}>
							<Col
								dangerouslySetInnerHTML={{ __html: separators.products.title }}
								className='title serif-font'
							></Col>
						</Fade>

						{/* Description */}
						<Fade triggerOnce={true} direction='left' delay={40}>
							<Col
								dangerouslySetInnerHTML={{
									__html: separators.products.description,
								}}
								className='description'
							></Col>
						</Fade>
					</Row>

					{/* Content */}
					<Row xs={2} sm={2} md={3} xxl={5} className='g-4 position-relative'>
						{products
							.filter((_, index) => index < currentProductsCount)
							.map((product, index) => (
								<Fade
									triggerOnce={true}
									key={index}
									direction='up'
									delay={index * 20}
								>
									<Col>
										<ProductHomeCardComponent product={product} />
									</Col>
								</Fade>
							))}

						{/* See More Button */}
						<Container fluid className='see-more-btn px-0'>
							<Fade triggerOnce={true} direction='right' delay={100}>
								<SeeMoreButtonComponent
									disabled={
										currentProductsCount >= products.length ? true : false
									}
									link={'/products'}
								/>
							</Fade>
						</Container>
					</Row>
				</Container>
			</Container>

			{/* Our Vision */}
			<HeroSectionComponent content={separators.ourVision} />

			{/* Partners */}
			<PartnersComponent partners={partners} />

			{/* Separator #3 - We Are Always There */}
			<SeparatorComponent
				content={separators.alwaysThere}
				id='always-there'
				hasSerifFont={true}
			/>

			{/* Get In Touch */}
			<Container fluid className='contact-container position-relative'>
				<Container className='custom-container'>
					{/* Section Header */}
					<Row xs={1} className='header g-4'>
						{/* Title */}
						<Fade triggerOnce={true} direction='left' delay={20}>
							<Col
								dangerouslySetInnerHTML={{ __html: separators.contact.title }}
								className='title serif-font'
							></Col>
						</Fade>

						{/* Description */}
						<Fade triggerOnce={true} direction='left' delay={40}>
							<Col
								dangerouslySetInnerHTML={{
									__html: separators.contact.description,
								}}
								className='description'
							></Col>
						</Fade>
					</Row>

					{/* Content */}
					<Row xs={1} className='g-4'>
						{/* Contact Form */}
						<Col>
							<ContactFormComponent postRoute='/quotation' />
						</Col>
					</Row>
				</Container>
			</Container>
		</Container>
	);
};

export default HomePage;
