const { createSlice } = require('@reduxjs/toolkit');

const cartSlice = createSlice({
	initialState: JSON.parse(localStorage.getItem('cartItems')) ?? [],
	name: 'cartSlice',
	reducers: {
		addToCart: (state, action) => {
			state.push(action.payload);
			localStorage.setItem('cartItems', JSON.stringify(state));
		},
		deleteFromCart: (state, action) => {
			const products = state.filter(
				(product) => product.id !== action.payload.id
			);
			localStorage.setItem('cartItems', JSON.stringify(products));
			return products;
		},
		clearCart: (state, action) => {
			localStorage.setItem('cartItems', JSON.stringify([]));
			return [];
		},
	},
});

export const { addToCart, deleteFromCart, clearCart } = cartSlice.actions;
export default cartSlice.reducer;
